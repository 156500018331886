<template>
	<div class="convention-hall page">
		<van-nav-bar class="nav-bar" :title="$t('choose.title')" />
		<div class="banner">
			<swiper-slide v-for="(v, key) in banners" :key="key">
				<van-image class="banner_img" :src="v.url">
					<template v-slot:loading>
						<van-loading type="circular" />
					</template>
				</van-image>
			</swiper-slide>
		</div>
		<div class="convention-item">
			<van-tabs animated sticky line-width="100px" :swipeable="true">
				<van-tab title="VIP"></van-tab>
			</van-tabs>
				
			<div class="movie_list_1">
				<div class="movie-list-item" v-for="(v, key) in movielist_1" :key="key"
					@click="toProfile(v.id)">
					<div class="horizontalItem">
						<div class="imgBox">
							<div class="van-image"
								style="width: 120px; height: 120px; overflow: hidden; border-radius: 8px;">
								<img :src="v.img_url" class="van-image__img" style="object-fit: cover;">
							</div>
							<span><i>{{$t('home.high_quality')}}</i></span>
						</div>
						<div style="width: calc(100% - 140px);">
							<div class="top">
								<div>
									<van-image src="img/home/vip.png" style="width: 13px;"></van-image>
									<span>{{$t('home.certification')}}</span>
								</div>
								<div>
									<van-image src="img/home/vip.png" style="width: 13px;"></van-image>
									<span>{{$t('home.video_certification')}}</span>
								</div>
								<div> {{ v.xuanfei_name }}</div>
							</div>
							<div class="tags" v-if="v.flag">
								<!-- <template v-for="v1 in v.flag.split(',')"> -->
								<van-tag class="van-tag--large van-tag--large">{{ v.flag.split(',')[0]
									}}</van-tag>
								<van-tag class="van-tag--large van-tag--success">{{ v.flag.split(',')[1]
									}}</van-tag>
								<van-tag class="van-tag--large van-tag--danger">{{ v.flag.split(',')[2]
									}}</van-tag>
								<van-tag class="van-tag--large van-tag--warning">{{ v.flag.split(',')[3]
									}}</van-tag>
								<!-- </template> -->
							</div>

							<div style="display: flex; align-items: center;">
								<span style="font-size: 14px; color: rgb(102, 102, 102);">{{$t('home.price_space')}}：</span>
								<div class="van-rate" tabindex="0" role="radiogroup">
									<div v-for="star in totalStars" :key="star" role="radio" tabindex="0"
										:aria-setsize="totalStars" :aria-posinset="star"
										:aria-checked="star <= v.number" class="van-rate__item">
										<i class="van-icon van-icon-star van-rate__icon"
											:class="{ 'van-rate__icon--full': star <= v.number, 'van-rate__icon--empty': star > v.number }"
											style="font-size: 18px;">
										</i>
									</div>
								</div>
							</div>
							<div style="color: rgb(153, 153, 153); font-size: 12px; margin: 5px 0px;">
								{{$t('home.hight')}}: {{ v.hight }}cm
								{{$t('home.bust')}}:{{ v.bust }}
								{{$t('home.service_space')}}
								{{$t('home.tracffic')}}
							</div>
							<div class="yueta">{{$t('home.yueta')}}</div>
						</div>
					</div>

				</div>
				<div class="hot-recommend-more" @click="gotoMenu('/Video')">{{ $t('home.see_more') }}</div>
			</div>


			




		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			totalStars: 5, // 总星星数量
			currentRating: 5, // 当前评分值，这里假设默认是5
			banners: [{}],
			movielist_0: [{}, {}, {}, {}],
			movielist_1: [{}, {}, {}, {}, {}, {}, {}, {}],
		};
	},
	methods: {
		onRefresh() {
			setTimeout(() => {
				this.getBasicConfig();
				this.isLoading = false;
				this.$toast(this.$t('fresh_success'));
			}, 500);
		},
		getBasicConfig() {
			this.$http({
				method: 'get',
				url: 'sys_config'
			}).then(res => {
				this.basicData = res.data;
				this.getBanner(this.basicData); //获取banner
				this.getMovieList_0(this.basicData); //获取首页视频0
				this.getMovieList_1(this.basicData); //获取首页视频1
			})

		},
		getMovieList_0(data) {
			this.movielist_0 = data.xuanfeilist
		},
		getMovieList_1(data) {
			this.movielist_1 = data.xuanfeilist
		},
		getBanner(data) {
			this.banners = data.banners;
		},
		toProfile(id) {
			if (!localStorage.getItem('token')) {
				this.$router.push({
					path: '/Login'
				})
			} else {
				this.$router.push({
					path: '/Profile?id=' + id
				})
			}
		},
	},
	created() {
		this.getBasicConfig();
	}
};
</script>

<style lang="less" scoped>
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #f2f2f5;
}

.nav-bar {
	background: linear-gradient(30deg, #7d76ef, #d63f8c);
	height: 100px;
}

.van-nav-bar {
	line-height: 50px;
}

::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #ffffff;
	font-size: 35px;
}

::v-deep .van-nav-bar__content {
	height: 100px;
}

.van-sidebar {
	width: 180px;
}

.convention-hall {
	display: flex;
	flex-direction: column;
	bottom: 20px;
	background: #f2f2f5;
}

::v-deep .van-tab {
	font-size: 30px;
	line-height: 100px;
	font-weight: bold;
	color: rgb(197, 113, 207);
}

::v-deep .van-tabs__line {
	background-color: #7e5678;
}

::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}

::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}


.horizontalItem {
	display: flex;
	border-bottom: 1px solid #eee;
	padding: 20px 0 4vw;
}

.horizontalItem .imgBox {
	position: relative;
	overflow: hidden;
	border-radius: 8px;
	margin-right: 20px;
}

.horizontalItem .imgBox>span {
	position: absolute;
	z-index: 9;
	background-color: #ebcaaf;
	color: #8d684b;
	transform: rotate(45deg);
	width: 20vw;
	height: 20vw;
	top: -11vw;
	right: -11vw;
}

.horizontalItem .imgBox>span>i {
	position: absolute;
	bottom: 5px;
	left: 50%;
	transform: translateX(-50%);
	font-weight: 700;
	font-size: 4vw;
}

.horizontalItem .top {
	display: flex;
}

.horizontalItem .top>div:first-child,
.horizontalItem .top>div:nth-child(2) {
	background: linear-gradient(180deg, #e7caaf, #fff7ed);
	color: #a4826b;
	font-weight: 600;
	font-size: 3vw;
	padding: 3px;
	margin-right: 10px;
}

.horizontalItem .top>div:last-child {
	margin-left: 4vw;
	font-size: 4vw;
}

.horizontalItem .tags {
	display: flex;
	margin: 5px 0 5px;
	flex-wrap: wrap;
}

.horizontalItem .tags>.van-tag {
	padding: 5px;
	font-size: 25px;
	margin: 0 5px 5px 0;
	border-radius: 8px;
	line-height: 30px;
}

.horizontalItem .yueta {
	background: linear-gradient(90deg, #df35ad, #4f1db5);
	color: #fff;
	border-radius: 8px;
	padding: 10px 0;
	text-align: center;
	font-size: 4vw;
}

.gdgride {
    padding-right: 40px;
}
.gdgride>div {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
}

.gdgride>div>span {
    position: absolute;
    z-index: 9;
    background-color: #ebcaaf;
    color: #8d684b;
    transform: rotate(45deg);
    width: 20vw;
    height: 20vw;
    font-size: 3vw;
    top: -11vw;
    right: -11vw;
}
.gdgride>div>span>i {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 700;
}




</style>
